import { atom, useAtom } from 'jotai';
import type { UseModalState } from '../state/modal';

export interface UseRemoveProfileModalState extends UseModalState {
  options: {
    onConfirm?: () => void;
  };
}

const modalAtom = atom<UseRemoveProfileModalState>({
  show: false,
  options: {},
});

/**
 * Use this hook to show/hide the `RemoveProfileModal`
 * from anywhere in the app
 */
export function useRemoveProfileModal() {
  return useAtom(modalAtom);
}
