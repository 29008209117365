import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { usePosthogInit } from './hooks/use-posthog-init';
import { makeQueryClient } from './utils/query-client-factory';
import { getSentry } from './utils/sentry';

const queryClient = makeQueryClient();

const Root = ({ children }: any) => {
  // force load sentry
  useEffect(() => {
    const timer = setTimeout(() => getSentry()?.forceLoad(), 2000);
    return () => clearTimeout(timer);
  }, []);

  // posthog glue
  usePosthogInit();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default Root;
