import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

const modalCounterAtom = atom(0);

export interface UseModalCounterOptions {
  readOnly?: boolean;
}

/**
 * This hook is used for keeping track of how many modals
 * are open at any given time. It's automatically used by ModalLayout,
 * so modals don't have (and should not) use it directly.
 */
export function useModalCounter(options?: UseModalCounterOptions) {
  const [count, setCounter] = useAtom(modalCounterAtom);
  const { readOnly } = options ?? {};

  useEffect(() => {
    if (!readOnly) {
      setCounter((counter) => counter + 1);
      return () => setCounter((counter) => counter - 1);
    }
  }, [setCounter, readOnly]);

  return count;
}
