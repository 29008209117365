import type { SetStateAction } from 'jotai';
import React, { Suspense, useCallback } from 'react';
import { usePoliciesWarningModal } from '../hooks/use-policies-warning-modal';
import { useRemoveDocumentModal } from '../hooks/use-remove-document-modal';
import { useRemovePhotoModal } from '../hooks/use-remove-photo-modal';
import { useRemoveProfileModal } from '../hooks/use-remove-profile-modal';
import type { ModalProps, UseModalState } from '../state/modal';

const RemoveProfileModal = React.lazy(
  () => import(/* webpackPreload: true */ './remove-profile-modal')
);

const RemovePhotoModal = React.lazy(
  () => import(/* webpackPreload: true */ './remove-photo-modal')
);

const RemoveDocumentModal = React.lazy(
  () => import(/* webpackPreload: true */ './remove-document-modal')
);

const PoliciesWarningModal = React.lazy(
  () => import(/* webpackPreload: true */ './policies-warning-modal')
);

/**
 * This hook is just a helper to help reduce boilerplate
 * when adding more modals (DRY)
 */
function useRenderModal(
  useModal: () => [
    UseModalState,
    (update: SetStateAction<UseModalState>) => void
  ],
  Component: React.LazyExoticComponent<React.ComponentType<ModalProps>>
) {
  const [state, setState] = useModal();
  const hide = useCallback(() => {
    setState((state) => (state.show ? { ...state, show: false } : state));
  }, [setState]);

  return (
    <Suspense>
      {state.show ? <Component options={state.options} hide={hide} /> : null}
    </Suspense>
  );
}

/**
 * This component is responsible for rendering all modals
 * available within the app. It should only be used once,
 * within Layout.tsx, so modals are available to any page.
 */
const ModalManager = () => {
  return (
    <>
      {useRenderModal(useRemoveProfileModal, RemoveProfileModal)}
      {useRenderModal(useRemovePhotoModal, RemovePhotoModal)}
      {useRenderModal(useRemoveDocumentModal, RemoveDocumentModal)}
      {useRenderModal(usePoliciesWarningModal, PoliciesWarningModal)}
    </>
  );
};

export default ModalManager;
