import { memo } from 'react';
import ModalManager from './components/modal-manager';
import { useModalCounter } from './hooks/use-modal-counter';

const Memo = memo(({ children }: any) => children);
Memo.displayName = '';

const Layout = ({ children }: any) => {
  const modalCount = useModalCounter({ readOnly: true });
  return (
    <>
      <div aria-hidden={modalCount > 0}>
        <Memo>{children}</Memo>
      </div>
      <ModalManager />
    </>
  );
};

export default Layout;
