import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { handleRequestErrors } from './handle-request-errors';

function onError(error: unknown) {
  if (error && typeof error === 'object' && error instanceof Error) {
    handleRequestErrors(error);
  }
}

export function makeQueryClient() {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({ onError }),
    mutationCache: new MutationCache({ onError }),
  });
  return queryClient;
}
