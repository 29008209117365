/**
 * Backend API address, including the protocol, without a trailing slash
 * @example 'https://api.findigs.com'
 */
export const API_SERVER: string = process.env.GATSBY_API_SERVER as string;

/**
 * Renter dashboard address, including the protocol, without a trailing slash
 * @example 'https://rent.findigs.com'
 */
export const RENT_DOMAIN: string = process.env.GATSBY_RENT_DOMAIN as string;

/**
 * Old trinity address, including the protocol, without a trailing slash
 * @example 'https://app.findigs.com'
 */
export const OLD_TRINITY_DOMAIN: string = process.env
  .GATSBY_OLD_TRINITY_DOMAIN as string;

/**
 * This is only used when hosting the app inside a directory other than
 * the root (/) of the domain. In practice, this is only being used in
 * PR previews.
 * @example '/test'
 */
export const PATH_PREFIX: string | undefined =
  process.env.GATSBY_PATH_PREFIX ?? undefined;

/**
 * Stripe's publishable key, used within the context of the Stripe.js SDK
 * @link https://stripe.com/docs/keys
 */
export const STRIPE_PUBLISHABLE_KEY: string = process.env
  .GATSBY_STRIPE_PUBLISHABLE_KEY as string;

/**
 * Reference to the server which will host this gatsby site
 */

export const GATSBY_SERVER: string = process.env.GATSBY_SERVER as string;

/**
 * Sentry's loader script src url, used within the context of the Sentry SDK
 * @see https://docs.sentry.io/platforms/javascript/install/loader/
 */
export const SENTRY_SCRIPT_SRC: string | undefined =
  process.env.GATSBY_SENTRY_SCRIPT_SRC;

/**
 * This app's release version, extracted from package.json
 */
export const APP_VERSION: string = process.env.GATSBY_APP_VERSION ?? '0.0.0';

/**
 * This is the current environment the app is running on
 */
export const APP_ENV: string = process.env.GATSBY_APP_ENV ?? 'local';
