import { logout } from '../api/auth';
import { RENT_DOMAIN } from '../consts/env';
import type { RequestError } from './better-fetch';

export interface HandleRequestErrorsOptions {
  /** if set to `true`, no action will be taken (no side-effects) */
  dryRun?: boolean;
}

export function handleRequestErrors(
  error: RequestError<any> | Error,
  options?: HandleRequestErrorsOptions
): {
  handled: boolean;
} {
  if ('response' in error) {
    switch (error.response.status) {
      case 401:
      case 403: {
        if (!options?.dryRun) {
          logout();

          const query = new URLSearchParams();
          query.set(
            'redirectTo',
            window.location.pathname + (window.location.search ?? '')
          );
          window.location.replace(`${RENT_DOMAIN}/logout/?${query.toString()}`);
        }

        return { handled: true };
      }
      case 409:
        if (!options?.dryRun) {
          logout().then(() => {
            window.location.replace(`${RENT_DOMAIN}/`);
          });
        }
        return { handled: true };
      case 404:
        if (!options?.dryRun) {
          window.location.replace(`${RENT_DOMAIN}/`);
        }
        return { handled: true };
    }
  }
  return { handled: false };
}
