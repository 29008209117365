import { atom, useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import type { PetVerification } from '../api/pet-verification';
import type { UseModalState } from '../state/modal';

const LOCAL_STORAGE_KEY_PREFIX = 'PoliciesWarningModal.shown';

export interface UsePoliciesWarningModalState extends UseModalState {
  options: {
    onConfirm?: () => void;
  };
}

const modalAtom = atom<UsePoliciesWarningModalState>({
  show: false,
  options: {},
});

/**
 * Use this hook to show/hide the `PoliciesWarningModal`
 * from anywhere in the app
 */
export function usePoliciesWarningModal() {
  return useAtom(modalAtom);
}

/**
 * Use this hook to show the `PoliciesWarningModal` on mount,
 * if applicable, from anywhere in the app.
 *
 * Guaranteed to only be displayed once per PV
 */
export function useShowPoliciesWarningModalIfApplicable(
  verification?: PetVerification
) {
  const run = useRef(false);

  const [, setModal] = usePoliciesWarningModal();

  useEffect(() => {
    if (run.current || !verification) {
      return;
    }

    const key = `${LOCAL_STORAGE_KEY_PREFIX}[${verification.id}]`;

    const alreadyShown: boolean | null = JSON.parse(
      localStorage.getItem(key) ?? 'null'
    );

    if (alreadyShown) {
      return;
    }

    if (verification.applicant_type === 'post_submission_applicant') {
      run.current = true;
      setModal({
        show: true,
        options: { onConfirm: () => localStorage.setItem(key, 'true') },
      });
    }
  }, [verification, setModal]);
}
