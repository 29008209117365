import { atom, useAtom } from 'jotai';
import type { UseModalState } from '../state/modal';

export interface UseRemovePhotoModalState extends UseModalState {
  options: {
    onConfirm?: () => void;
  };
}

const modalAtom = atom<UseRemovePhotoModalState>({
  show: false,
  options: {},
});

/**
 * Use this hook to show/hide the `RemovePhotoModal`
 * from anywhere in the app
 */
export function useRemovePhotoModal() {
  return useAtom(modalAtom);
}
