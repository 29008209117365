import { atom, useAtom } from 'jotai';
import type { UseModalState } from '../state/modal';

export interface UseRemoveDocumentModalState extends UseModalState {
  options: {
    description: string;
    onConfirm?: () => void;
  };
}

const modalAtom = atom<UseRemoveDocumentModalState>({
  show: false,
  options: {
    description: '',
  },
});

/**
 * Use this hook to show/hide the `RemoveDocumentModal`
 * from anywhere in the app
 */
export function useRemoveDocumentModal() {
  return useAtom(modalAtom);
}
