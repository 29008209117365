import { useEffect } from 'react';

/**
 * our JWT tokens contain the user's id
 * this function parses the token and extracts it
 */
function tryParseUserIdFromToken(token: string) {
  try {
    const chunks = token.replace('JWT ', '').split('-')[0].split('.');

    for (const chunk of chunks) {
      try {
        const parsed = JSON.parse(atob(chunk));
        if (
          typeof parsed === 'object' &&
          'user_id' in parsed &&
          parsed.user_id
        ) {
          return parsed.user_id as string;
        }
      } catch {
        // no-op
      }
    }
  } catch {
    // no-op
  }
  return null;
}

/**
 * Gatsby-specific posthog initialization code
 *
 * Should be called exclusively in `applications/pets/src/root.tsx`
 */
export function usePosthogInit() {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const timer = setInterval(() => {
      if (!window.posthog?.identify) return;

      const token = localStorage.getItem('user_token');
      if (!token) return;

      const userId = tryParseUserIdFromToken(token);
      if (!userId) return;

      clearInterval(timer);

      window.posthog.identify(userId);
    }, 250);
    return () => clearInterval(timer);
  }, []);
}
