import { API_SERVER } from '../consts/env';

const USER_TOKEN_FIELD_NAME = 'user_token';

export class NotAuthenticatedError extends Error {
  constructor() {
    super();
    this.name = 'NotAuthenticatedError';
    this.message = 'User is not authenticated';
  }
}

interface User {
  type: 'tenant' | 'manager';
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface LoginResponse {
  token?: string;
  user?: User;
}

export type GetUserDetailsResponse = User;

/**
 * Gets user details, takes props in case the user is not yet authenticated.
 *
 * If a token is provided, this function will automatically save the token.
 */
export async function getUserDetails(): Promise<GetUserDetailsResponse> {
  const token = getUserToken();

  if (!token) {
    throw new NotAuthenticatedError();
  }

  const url = `${API_SERVER}/users/detail/`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  const user = await fetch(url, requestOptions).then(
    handleResponse<GetUserDetailsResponse>
  );

  return user;
}

export async function login({
  email,
  password,
}: LoginProps): Promise<LoginResponse> {
  const url = `${API_SERVER}/rest-auth/login/`;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: email, email, password }),
  };

  const user = await fetch(url, options).then(handleResponse<LoginResponse>);
  if (user.token) {
    saveUserToken(user.token);
  }
  return user;
}

export async function logout() {
  localStorage.removeItem(USER_TOKEN_FIELD_NAME);
}

export function getUserToken(): string | null {
  return localStorage.getItem(USER_TOKEN_FIELD_NAME);
}

export function saveUserToken(token: string) {
  const formatted_token = token.startsWith('JWT ')
    ? token
    : `JWT ${JSON.stringify(token).slice(1, -1)}`;

  return localStorage.setItem(USER_TOKEN_FIELD_NAME, formatted_token);
}

async function handleResponse<R>(response: Response): Promise<R> {
  const defaultError = { message: ['Unexpected Error Occurred'] };

  const text = await response.text();
  const data = text && JSON.parse(text);

  if (!response.ok) {
    if (response.status === 401) {
      await logout();
    }

    throw data || defaultError;
  } else if (data) {
    return data;
  } else {
    throw defaultError;
  }
}
